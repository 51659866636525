<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
.titles{
    font-size: 42px;
    color: #9d856b;
    font-weight: 600;
    position: relative;
}
.titles::after,.titles::before{
  content: '';
  height: 1px;
  width: 150px;
  background: #9d856b;
  position: absolute;
  top: 50%;
}
.titles::after{
  left: 280px;
}
.titles::before{
  right: 280px;
}
li {
  list-style-type: none;
}

#app {
  font-family: Source Han Sans CN, Helvetica Neue, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: #F7F9FD;
  // background: url("~@/assets/img/bg.png");
  background-size: 100% 100%;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0px;
  height: 8px;
  background-color: #05192b;
}

.more {
  color: #1043C7;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
}


/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: #05192b;
}

/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4aa8c6;
}

// 分页
.content-wrap {
  .el-pagination.is-background .el-pager li {
    background: #fff;
  }

  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    /* background-color: #037bf9; C0C4CC */
    background-color: #037BF9;
    color: #fff;
  }

  .el-pagination.is-background .btn-prev:disabled,
  .el-pagination.is-background .btn-next:disabled {
    color: #C0C4CC;
  }

  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .el-pager li {
    margin: 0 5px;
    background-color: #f4f4f5;
    color: #606266;
    min-width: 30px;
    border-radius: 2px
  }
}
</style>
