export default {
    //公共语言
    public:{
        home: 'HOME',
        products: 'PRODUCTS',
        aboutUs: 'ABOUT US',
        OurProducts: 'Our Products',
        LearnMore : 'Learn More',
        submitTo: 'SUBMIT TO',
        Contactus: 'Contact us',
        Followus: 'Follow us',
        PlushSeries: 'Plush Series',
        Name: 'Name',
        Telephone: 'Telephone',
        Email: 'E-mail',
        Yourinformation: 'Your information',
        Wearefrom: 'We are from Suzhou, China. We love pets, and we aspire to become the most professional pet toy experts in China.',
        ProductCenter:'ProductCenter',
        productDetails:'productDetails',
        MerchandiseDetails:'MerchandiseDetails',
        RecommendedProducts:'RecommendedProducts',
        Searchproducts:'Search products',
    },
}