import Vue from 'vue'
import VueI18n from 'vue-i18n'


import Chinese from './zh_CN'
import Tibetan from './za_CN'
Vue.use(VueI18n)

const messages = {
    tib: {
        ...Tibetan,
    },
    zh: {
        ...Chinese,
    },
}
let language = ''
if(localStorage.getItem('lang') == 2){
    language = 'tib'
}else{
    language = 'zh'
}
// 设置title
// document.title = Chinese.public.name
const i18n = new VueI18n({
    locale: language,
    messages
})

export default i18n