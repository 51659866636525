import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import i18n from '@/lang';
import '@/assets/css/index.scss'; //颜色修改
Vue.use(ElementUI);

import '@/assets/css/color.css';
import '@/assets/css/style.css';
import jQuery from 'jquery'
jQuery(window).on('load', function () {
  jQuery("body").addClass("page-loaded");
   ("loaded")
});

import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

import Topnav from './components/Topnav';
Vue.component('Topnav', Topnav);
import Footernav from './components/Footernav';
Vue.component('Footernav', Footernav);
import FormInput from './components/FormInput';
Vue.component('FormInput', FormInput);
import ProductList from './components/ProductList';
Vue.component('ProductList', ProductList);
// 轮播
import Carousel from './components/Carousel';
Vue.component('Carousel', Carousel);

import Organization from '@/components/projectComponents/organization.vue'; //下拉树
Vue.component('organization', Organization);

import Public from './utils/Public';
Vue.prototype.$public = Public;

//写在main.js中
Vue.prototype.$bus = new Vue()

router.afterEach(() => {
  // console.log(to,from,next);
  document.getElementById('top-nav').scrollIntoView({ behavior: 'smooth' })
  // document.body.scrollTop =0;
  // document.documentElement.scrollTop = 0;
});

// 全局配置防止多次点击
Vue.directive('preventReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 2000);
      }
    });
  },
});

// 統一登陆 先从本地存储拿token没有的话在从地址栏找
function queryURLParams(URL) {
  let url = URL.split('?')[1];
  const urlSearchParams = new URLSearchParams(url);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
}

let URL = window.location.href;
let token = queryURLParams(URL).Token;
let id = queryURLParams(URL).id;
let name = queryURLParams(URL).name;
let device = queryURLParams(URL).device;
if (token) {
  localStorage.setItem('Token', token);
  localStorage.setItem('userId', id);
  localStorage.setItem('loginName', name);
  localStorage.setItem('device', device);
}

const vueMain = new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
export default vueMain;