export default {
    // 字典
    dictionary(code) {
        const dictionaryList = JSON.parse(localStorage.getItem('dictionaryList'))
        let zdList = [];
        dictionaryList.forEach(key => {
            if (key.code == code) {
                zdList.push(key)
            }
        });
        return zdList;
    },
    /**
     * 获取字典根据父级（暂时没有父级字段 暂用remarks 代提）
     * @param code
     * @param parent
     * @returns {[]}
     */
    dictByCodeAndRemarks(code, remarks) {
        remarks += ""
        const dictionaryList = JSON.parse(localStorage.getItem('dictionaryList'))
        let dictArray = [];
        dictionaryList.forEach(key => {
            if (key.code == code) {
                if (key.remarks == remarks || (null != remarks && remarks == 0)) {
                    dictArray.push(key)
                }
            }
        });
        return dictArray;
    },
    /**
     * 数字转成汉字
     * @params num === 要转换的数字
     * @return 汉字
     * */
    toChinesNum(num) {
        let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
        let unit = ['', '十', '百', '千', '万']
        num = parseInt(num)
        let getWan = (temp) => {
            let strArr = temp.toString().split('').reverse()
            let newNum = ''
            let newArr = []
            strArr.forEach((item, index) => {
                newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index])
            })
            let numArr = []
            newArr.forEach((m, n) => {
                if (m !== '零') numArr.push(n)
            })
            if (newArr.length > 1) {
                newArr.forEach((m, n) => {
                    if (newArr[newArr.length - 1] === '零') {
                        if (n <= numArr[numArr.length - 1]) {
                            newNum += m
                        }
                    } else {
                        newNum += m
                    }
                })
            } else {
                newNum = newArr[0]
            }

            return newNum
        }
        let overWan = Math.floor(num / 10000)
        let noWan = num % 10000
        if (noWan.toString().length < 4) {
            noWan = '0' + noWan
        }
        return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
    },
    //随机生成颜色
    randomBg() {
        let arrColor = []
        for (let i = 0; i < 100; i++) {
            let colors = '#' + Math.floor(Math.random() * 0xffffff).toString(16);
            let flag = true
            for (var j = 0; j <= arrColor.length; j++) {
                if (colors == arrColor[j]) {
                    flag = false;
                    break;
                }
            }
            if (flag) {
                arrColor.push(colors)
            } else {
                i--;
            }
        }
        return arrColor
    },
    //   路由跳转
    RouteForwarding(path) {
        this.$router.replace({
            path: "/" + path,
        });
    }

}
