import Vue from 'vue'
import { axios } from '@/api/index'
Vue.prototype.axios = axios  //axios实例
import Qs from 'qs'


export function postAction(url,parameter) {
  return axios({
    url: url,
    method:'post' ,
    data: Qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
}
export function postActionfile(url,parameter) {
  return axios({
    url: url,
    method:'post' ,
    data: parameter,
  })
}
export function postActionexcel(url,parameter) {
  return axios({
    url: url,
    method:'post' ,
    data:parameter,
  })
}

export function postActionJson(url,parameter) {
  return axios({
    url: url,
    method:'post' ,
    data: parameter,
    headers: {
       'Content-Type': 'application/json',

    },
  })
}

//get
export function getActionpcode (url, parameter) {
  return axios({
    url: url,
    method: 'get',
    params: parameter,
    responseType: 'blob', //此属性非常重要，不然数据是乱码
  })
}
//get
export function getAction(url,parameter) {
  return  axios({
    url: url,
    method: 'get',
    params: parameter,

  })
}

//DELETE
export function deleteAction(url,parameter) {
  return  axios({
    url: url,
    method: 'DELETE',
    params: parameter,


  })
}

export function potActionlist(url,urldata,bodydata) {
  return axios({
    url: url+urldata,
    method: 'post',
    data: bodydata,
  })
}
//post method= {post | put}
export function httpAction(url,parameter) {
    return axios({
        url: url,
        method:"post" ,
        data: parameter
    })
}
//get 下载excel
export function getExcel (url, parameter) {
  return axios({
    url: url,
    method: 'get',
    params: parameter,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'blob', //此属性非常重要，不然数据是乱码

  })
}


