import Vue from "vue";
import VueRouter from "vue-router";
// import Login from '../views/Login.vue';
import NotFound from "@/components/404.vue";

// 解决vue路由传递参数时，重复点击会抛出NavigationDuplicated的警告错误
const originalPush = VueRouter.prototype.push;
const originReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(Location) {
    return originalPush.call(this, Location).catch((err) => err);
};

VueRouter.prototype.replace = function push(Location) {
    return originReplace.call(this, Location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
    {
        path: "*",
        component: NotFound,
    },
    {
        path: "Nopermission",
        component: () => import("../components/Nopermission"),
    },

    // {
    //     path: "/",
    //     name: "首页",
    //     component: () => import("../views/index"),
    //     redirect: "/home",
    // },
    // {
    //     path: "/unifyHome",
    //     name: "首页",
    //     component: () => import("../views/index"),
    //     redirect: "/home",
    // },
    {
        path: "/",
        name: "首页",
        component: () => import("../views/index"),
        redirect: "/home",
    },
    {
        path: "/unifyHome",
        name: "首页",
        component: () => import("../views/index"),
        redirect: "/home",
    },
    {
        path: "/home",
        name: "首页",
        component: () => import("../views/index"),
        children: [
            // 首页
            {
                path: "/home",
                name: "首页",
                component: () => import("../views/Home/index"),
            },
            {
                path: "/Products",
                name: "产品",
                component: () => import("../views/Products/index.vue"),
            },
            {
                path: "/Aboutus",
                name: "关于我们",
                component: () => import("../views/Aboutus/index.vue"),
            },
            {
                path: "/Frenk",
                name: "Frenk",
                component: () => import("../views/Frenk/index.vue"),
            },
            {
                path: "/ProductDeta",
                name: "产品详情",
                component: () => import("../views/ProductDeta/index.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    routes,
});

export default router;
