<template>
  <div class="context">
    <img src="../assets/not_found.png">
    <p>该功能正在开发中...</p>
    <div>
      <el-button type="primary" plain @click="goHome()">返回上级</el-button>
    </div>
  </div>
</template>
<script>

  export default {
    components:{

    },
    data(){
      return{
        input:'',
      }
    },



    created() {

    },



    methods:{
      goHome(){
        this.$router.go(-1)
      }

    }
  }
</script>
<style scoped>
  html,body {
    height: 100%;
  }
  #app {
    height: 100%;
    margin-top: 0;
  }
  .context {
    position: absolute;
    top: 20%;
    width: 100%;
    text-align: center;
  }
  .context img {
    max-width: 100%;
    max-height: 100%;
  }
  .context p {
    margin: 20px;
    font-size: 16px;
    color: #0d3349;
  }
</style>
