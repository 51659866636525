export default {
    //公共语言
    public: {
        home: '首页',
        products: '产品',
        aboutUs: '关于我们',
        OurProducts: '我们的产品',
        LearnMore : '查看更多',
        submitTo: '确 定',
        Contactus: '联系我们',
        Followus: '关注我们',
        PlushSeries: '查看详情',
        Name: '姓名',
        Telephone: '电话',
        Email: '邮箱',
        Yourinformation: '您的信息',
        Wearefrom: '我们来自中国苏州。我们热爱宠物，立志成为中国最专业的宠物玩具专家。',
        ProductCenter:' 产品中心',
        productDetails:'产品详情',
        MerchandiseDetails:'商品详情',
        RecommendedProducts:'推荐产品',
        Searchproducts:'搜索产品',
    },
}