import axios from 'axios';
import vueMain from '@/main'
// 创建 axios 实例
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_URL, // api base_url
    timeout: 60000 // 请求超时时间
})

/* 请求拦截器 */
service.interceptors.request.use(
    config => {
        // const token = localStorage.getItem('Token') //获取token
        // let device = localStorage.getItem('device') //获取device
        // if (device == 'web') {
        //     device = 'web'
        // } else {
        //     device = 'unify'
        // }
        // if (token) {
        //     config.headers['TOKEN'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
        //     config.headers['device'] = device
        //     config.headers['sysMod'] = '2'
        // }
        
        // let lang = localStorage.getItem('lang') //获取lang
        // if (!lang || lang == 1) {
        //     lang = 1
        // } else {
        //     lang = 2
        // }
        // config.headers['lang'] = lang
        return config;
    },
    error => {
        return Promise.reject(error);

    }
);


/* 响应拦截器 */
service.interceptors.response.use(
    response => {
        if (response.data.code === 10015 || response.data.code === 10006 || response.data.code === 401) {
            //token失效/为空
            // console.log(response.data.code);
            localStorage.removeItem('Token')
            localStorage.removeItem('userId')
            localStorage.removeItem('loginName')
            localStorage.removeItem('device')
        }

        return response;
    },
    error => {
        // 自定义加载动画结束
        console.log('--------------响应拦截------------')
        try {
            if (error.response) {
                switch (error.response.code) {
                    case 10015:  //token过期
                        // console.log(error.response.code);
                        localStorage.removeItem('Token')
                        localStorage.removeItem('userId')
                        localStorage.removeItem('loginName')
                        localStorage.removeItem('device')
                        setTimeout(()=>{
                            vueMain.$router.push({path: '/login'})
                        },500)
                        return;
                }
            }
            return Promise.reject(error.reponse.data)
        }
        catch (e) {
            console.log(e)
        }
    },

    error => {
        return Promise.reject(error)
    }
)
export {
    service as axios
}
