<template>
  <div id="top-nav" class="top-nav mobile">
    <div class="nav-box">
      <div class="logo-box">
        <img src="~@/assets/img/logo.png" alt="" />
      </div>
      <div class="nav-tab">
        <div class="sousuo-box">
          <div class="sousuo-re">
            <el-input
              class="input"
              v-model="search" clearable
              :placeholder="$t('public.Searchproducts')"
            ></el-input>
            <img class="sousuo-img" @click="sousuoFuc" src="~@/assets/img/sousuo.png" alt="" />
          </div>
          <el-dropdown placement="bottom" @command="changeLanguage">
              <img v-if="lang == 1" class="traduire" src="@/assets/chentab1.png" alt="" />
              <img v-else class="traduire" src="@/assets/chentab2.png" alt="" />
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">中文</el-dropdown-item>
                <el-dropdown-item command="2">en</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="nav-ul" :class="lang == 2?'':'nav-ul-list-w'">
          <div class="nav-list" v-for="(item,index) in menu" :key='index'
            :class="menuIndex == index?'active':''" @click="menuFuc(item,index)">
            <span v-if="item.id != 4">{{lang == 2?item.label:item.labelCh}}</span>
            <img v-else class="frenk" src="~@/assets/img/frenk.png" alt="" />
          </div>
          <div class="nav-list nav-listr nav-listr1">
            <el-popover
              placement="bottom"
              width="150"
              trigger="hover">
              <img class="ewm" :src="imgUrl+infoDeta.wxImg" alt="" style="width: 100%;border-radius: 8px;" />
              <img slot="reference" class="nav-img" src="~@/assets/img/nav1.png" alt="" />
            </el-popover>
          </div>
          <div class="nav-list nav-listr nav-listr2">
            <el-popover 
              placement="bottom"
              title=""
              width="200"
              trigger="hover"
              :content="infoDeta.email">
                <img slot="reference" class="nav-img" src="~@/assets/img/nav2.png" alt="" />
            </el-popover>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/time";
import { getAction } from "@/api/manage";
export default {
  name: "Topnav",
  props: ["homeShow"],
  components: {},
  data() {
    return {
      formatDate,
      token: localStorage.getItem("Token"),
      name: localStorage.getItem("loginName"),
      userName: localStorage.getItem("loginName"),
      userType: false,
      menu: [
        {
          id: 1,
          iconHttpAc: require("@/assets/index/nav1.png"),
          iconHttp: require("@/assets/index/nav1_1.png"),
          label: "HOME",
          labelCh: "首页",
          path: "/home",
        },
        {
          id: 2,
          iconHttpAc: require("@/assets/index/nav2.png"),
          iconHttp: require("@/assets/index/nav2_1.png"),
          label: "PRODUCTS",
          labelCh: "产品",
          path: "/Products",
        },
        {
          id: 3,
          iconHttpAc: require("@/assets/index/nav3.png"),
          iconHttp: require("@/assets/index/nav3_1.png"),
          label: "ABOUT US",
          labelCh: "关于我们",
          path: "/Aboutus",
        },
        {
          id: 4,
          iconHttpAc: require("@/assets/index/nav4.png"),
          iconHttp: require("@/assets/index/nav4_1.png"),
          label: "frenk",
          path: "/Frenk",
        },
      ],
      menuIndex: 0,
      search: '',
      cmsWordsList: [],
      time: null,
      rumbList: [],
      lang: 1, // 1中文 2英文
      infoDeta: {},
      imgUrl: process.env.VUE_APP_BASE_IMG
    };
  },
  created() {
    if (
      localStorage.getItem("loginName") != "超级管理员" ||
      localStorage.getItem("userId") != 1
    ) {
      this.menu = this.menu.splice(0, 6);
      this.userType = false; //不是管理员
    } else {
      this.userType = true; //管理员
    }

    
  },
  mounted() {
    let lang = localStorage.getItem('lang') //获取lang
    if (!lang || lang == 1) {
        localStorage.setItem('lang',1)
        this.lang = 1
    }else {
      localStorage.setItem('lang',2)
      this.lang = 2
    }
    this.infoDetaFuc()
  },
  methods: {
    infoDetaFuc() {
      getAction(process.env.VUE_APP_BASE_URL + "/ewm/ewmImg/listData").then(
        (res) => {
          this.infoDeta = res.data.list[0]
          localStorage.setItem('infoDeta',JSON.stringify(this.infoDeta))
        }
      );
    },
    sousuoFuc() {
      console.log(this.search);
      if (this.search == '') {
        this.$message({
          message: '请输入查询内容',
          type: 'warning'
        });
        return false
      }
      
      if (this.$route.path == '/Products') {
        this.$bus.$emit('brotherEvent',this.search)
      } else {
        this.$router.push({
          path: "/Products",
          query: { search: this.search },
        });
      }
    },
    changeLanguage(lang) {
      // console.log(lang);
      localStorage.setItem('lang',lang)
      this.lang = lang
      let lan = lang == 1?'tib': 'zh'
      this.$i18n.locale = lan; // 切换语言
      window.location.reload()
    },
    handleSearch() {
      this.$router.push({
        path: "/search",
        query: { item: this.search },
      });
    },
  
    menuFuc(item, index) {
      this.menuIndex = index;
      this.$router.push({
        //核心语句
        path: item.path, //跳转的路径
      });
    },

    
  },
  watch: {
    "$route.path": {
      handler(to) {
        // console.log(to);
        this.token = localStorage.getItem("Token");
        this.name = localStorage.getItem("loginName");
        // console.log(this.homeShow);
        if (to == "/home") {
          this.$emit("homeShow", true);
        } else {
          this.$emit("homeShow", false);
        }
      },
    },
    $route: {
      immediate: true,
      handler() {
        for (let i in this.menu) {
          if (this.menu[i].labelCh == this.$route.name) {
            this.menuIndex = i;
          }
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.traduire {
  width: 40px;
  border-left: 1px solid #c5c5c5;
  padding-left: 10px;
  margin-left: 10px;
}
.nav-box {
  padding: 20px 100px 20px 80px;
  display: flex;
  align-items: end;
  display: flex;
  align-items: end;
  .input{
    width: 430px;
    font-size: 19px;
  }
  .logo-box {
    flex: 1;
  }
  .nav-tab {
    flex: 5;
  }
  .nav-ul {
    display: flex;
    align-items: end;
    justify-content: end;
  }
  .nav-list {
    font-size: 28px;
    color: #d1c1aa;
    font-weight: bold;
    padding: 0 30px;
    cursor: pointer;
    transition: all 0.5s;
    user-select: none;
  }
  .nav-ul-list-w{
    .nav-list{
       width: 120px;
    }
    .nav-listr1{
      margin-left: 230px;
    }
  }
  .active{
    color: #997645;
  }
 
  .nav-listr {
    padding: 0 25px;
    position: relative;
    top: 10px;
    width: 70px !important;
    
    .nav-img{
      border: 2px solid #bcaa94;
      border-radius: 50%;
      &:hover{
        animation: im_icon 1s linear infinite;
      }
    }
  }
  .nav-listr1 {
    margin-left: 210px;
  }
  .nav-listr2{
    padding-left: 30px;
    padding-right: 20px;
  }
  @keyframes im_icon{
    50%{
      transform:  scale(1.01,1.01);
      border: 2px solid #be8135;
    }
    80%{
      transform:  scale(0.98,0.98);
      border: 2px solid #d19750;
    }
    
  }

  .sousuo-box {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 20px;
  }
  .sousuo-re {
    position: relative;
  }
  .sousuo-img {
    position: absolute;
    top: 15px;
    left: 17px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .sousuo-input {
    width: 430px;
    height: 40px;
    border-radius: 40px;
    padding-left: 40px;
  }
}

@media (max-width: 768px){
  .nav-box{
    display: block;
  }
  .nav-box .nav-tab{
    flex: 10;
  }
  .nav-box .sousuo-box{
    margin-bottom: -4px;
  }
  .nav-box .input{
    width: 200px;
  }
  
  .nav-box .sousuo-img{
    top: 7px;
    left: 11px;
    width: 18px;
    height: 18px;
  }
  .nav-box{
    padding: 10px;
    .logo-box{
      img{
        width: 40px;
      }
    }
  }
  .nav-box .logo-box{
    position: absolute;
  }
  .nav-box .logo-box img{
    width: 32px;
  }
  .nav-box .nav-ul{
    padding: 18px 0 10px;
  }
  .nav-box .nav-list{
    font-size: 15px;
    padding: 0 0px;
    font-weight: initial;
    width: 25% !important;
    text-align: center;
  }
  .nav-box .nav-ul-list-w .nav-list{
     width: initial;   
  }
  .frenk{
    width: 54px;
  }
  .nav-box .nav-ul-list-w .nav-listr1,.nav-box .nav-listr{
    margin-left: 0px;
    width: 25px !important;
    display: none;
  }
  .nav-box .nav-ul-list-w .nav-listr1 img,
  .nav-box .nav-ul-list-w .nav-listr2 img{
    width: 25px;
  }

  .sousuo-box{
    display: none;
  }

  .traduire{
    width: 25px;
  }

  
}

</style>
<style scoped>
.top-nav >>> .el-input__inner{
  border-radius: 50px;
  padding-left: 48px;
  height: 55px;
  line-height: 55px;
  color: #d1c1aa;
}
@media (max-width: 768px){
.top-nav >>> .el-input__inner{
    padding-left: 35px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
  }
}
</style>