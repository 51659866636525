<template>
  <div>
    <el-select ref="selectUpResId" style="width: 100%" filterable :value="dropDown" :disabled="disableSubmit"
      placeholder="请选择所属区域" :filter-method="remoteMethod">
      <el-option hidden :value="dropDown" :label="upResName" placeholder="请选择所属区域"></el-option>
      <el-tree :value="defaultProps.value" ref="selectTree" accordion :data="organizationData" :props="defaultProps"
        :node-key="defaultProps.value" :default-expand-all="true" :expand-on-click-node="true"
        :check-on-click-node="false" @node-click="handleNodeClick" :filter-node-method="filterNode">
      </el-tree>
    </el-select>
  </div>
</template>
<script>
export default {
  props: ["downTrees"],
  data() {
    return {
      upResName: "",
      dropDown: "",
      organizationData: [],
      defaultProps: [],
      disableSubmit: '',
    };
  },
  watch: {
    downTrees: {
      immediate: true,
      handler() {
        this.dropDown = "";
        this.upResName = "";
        this.init();
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  mounted() {

  },
  methods: {
    init() {
      this.defaultProps = this.downTrees.defaultProps;
      this.disableSubmit = this.downTrees.disableSubmit
      this.organizationData = this.downTrees.organizationData;
      this.dropDown = this.downTrees.upResName;
    },
    remoteMethod(val) {
      this.upResName = val;
      this.$refs.selectTree.filter(val);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 下拉树
    handleNodeClick(data) {
      // 这里主要配置树形组件点击节点后，设置选择器的值；自己配置的数据，仅供参考
      this.upResName = data.name;
      this.$emit("TreeLists", data);
      // 选择器执行完成后，使其失去焦点隐藏下拉框的效果
      this.$refs.selectUpResId.blur();
    },
  },
};
</script>
<style>
.disabled .el-upload--picture-card {
  display: none;
}
</style>
