<template>
  <div ckey="box">
    <div class="footer-nav">
      <div class="logo">
        <img src="~@/assets/img/logo-footer.png" alt="">
      </div>
      <div class="us">
        <div class="titleac">{{lang == 1?'联系我们':'Contact us'}}</div>
        <div class="us-ul">
          <div class="us-list">
            <img src="~@/assets/img/us1.png" alt="">
            <span>{{infoDeta.email}}</span>
          </div>
          <div class="us-list">
            <img src="~@/assets/img/us2.png" alt="">
            <span>{{infoDeta.phone}}</span>
          </div>
          <div class="us-list">
            <img src="~@/assets/img/us3.png" alt="">
            <span v-if="lang == 1">{{infoDeta.addres}}</span>
            <span v-else>{{infoDeta.addresEn}}</span>
          </div>
        </div>
      </div>
      <div class="foll">
        <div class="titleac">{{lang == 1?'关注我们':'Follow us'}}</div>
        <div class="weixin">
          <el-popover
            placement="top"
            width="150"
            trigger="hover">
            <img class="ewm" :src="imgUrl+infoDeta.xhsImg" alt="" style="width: 100%;border-radius: 8px;" />
            <img slot="reference" class="weixin1" src="~@/assets/img/footer1.png" alt="">
          </el-popover>
          <el-popover
            placement="top"
            width="150"
            trigger="hover">
            <img class="ewm" :src="imgUrl+infoDeta.wxImg" alt="" style="width: 100%;border-radius: 8px;" />
            <img slot="reference" class="weixin2" src="~@/assets/img/footer2.png" alt="">
          </el-popover>
        </div>
      </div>
    </div>
    <div class="tiara">
      © <span>2024 TIARA</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footernav",
  components: {},
  data() {
    return {
      lang: 1,
      infoDeta: {},
      imgUrl: process.env.VUE_APP_BASE_IMG
    };
  },
  mounted() {
    this.lang = localStorage.getItem('lang') //获取lang
    this.infoDeta =JSON.parse(localStorage.getItem('infoDeta'))
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">

.footer-nav{
  background: url("~@/assets/img/footer.png");
  background-size: 100% 100%;
  height: 600px;
  display: flex;
  align-items: center;
  color: #fff;
  .logo,.us,.foll{
    flex: 1;
    position: relative;
    top: 70px;
  }
  .logo{
    text-align: center;
    img{
      width: 150px;
    }
  }
  .us{
    flex: 1;
    .titleac{
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 40px;
    }
    
    .us-ul{
      font-size: 18px;
      font-weight: 100;
      .us-list{
        margin-bottom: 20px;
      }
      img{
        position: relative;
        top: 2px;
      }
      span{
        margin-left: 20px;
      }
    }
  }
  .foll{
    flex: 1;
    height: 252px;
    .titleac{
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 40px;
      position: absolute;
      left: 80px;
    }
    .weixin{
      position: absolute;
      top: 100px;
      left: 80px;
      img{
        width: 70px;
      }
      .weixin1{
        margin-right: 50px;
      }
    }
  }
  .us::before,.us::after{
    content: '';
    position: absolute;
    background: #fff;
    width: 1px;
    height: 331px;
    top: -56px;
  }
  .us::before{
    left: -70px;
  }
  .us::after{
    right: 10px;
  }
  
}
.tiara{
  background: #9D856B;
  text-align: center;
  font-size: 18px;
  height: 45px;
  line-height: 45px;
  color: #fff;
  // span{
  //   font-weight: bold;
  // }
}
@media (max-width: 768px){
  .footer-nav{
    display: inherit;
    background-size: initial;
    margin-top: 0;
    height: 560px;
    .logo,.us,.foll{
      top: 180px;
    }
    .logo{
      img{
        width: 65px;
      }
    }
    .us{
      padding: 0 20px 10px 40px;
      .titleac{
        margin-bottom: 15px;
        font-size: 20px;
      }
    }
    .us::before,.us::after{
      width: 0;
    }
    .foll{
      .titleac{
        left: 40px;
        font-size: 20px;
      }
      .weixin{
        top: 55px;
      }
    }
  }
  .footer-nav .us .us-ul .us-list{
    margin-bottom: 9px;
  }
  .footer-nav .us .us-ul img{
    width: 15px;
  }
  .footer-nav .us .us-ul span{
    margin-left: 10px;
    font-size: 14px;
  }
  .footer-nav{
    background-position-x: -1100px;
  }
}
</style>
